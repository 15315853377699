import React from 'react'
import icon_1 from '../assets/icon_1.png';
import icon_2 from '../assets/icon_2.png';



export default function Footer() {
  return (
    <div className="">

      <footer className='text-white-100 mx-auto lg:px-20 font-inter px-5'>

        <div className="flex justify-between p-5 items-center border-2 border-yellow-100 lg:w-5/12 rounded-2xl mx-auto font-cehua mb-5">
          <p className='md:text-5xl text-3xl font-semibold uppercase'>
            join us
          </p>

          <button className='py-2 px-6 bg-yellow-200 rounded-lg text-2xl font-semibold'>
            Connect
          </button>
        </div>

        <p className='flex mx-auto lg:w-9/12 text-center py-5'>
          Disclaimer: Cryptocurrency may be unregulated in your jurisdiction. The value of cryptocurrencies may fluctuate.
          Profits may be subject to capital gains or other taxes applicable in your jurisdiction
        </p>

        <div className='lg:flex items-center justify-between py-6 lg:space-y-0 space-y-6'>
          <nav>
            <ul className="text-md flex flex-wrap justify-center gap-5 pt-4 items-center text-white-100">
              <li><a href="/" className="">Home</a></li>
              <li><a href="#tokenomics" className="">Tokenomics</a></li>
              <li><a href="#roadmap" className="">Roadmap</a></li>
              <li><a href="/" className=''>Governance</a></li>
            </ul>
          </nav>


          <div className='flex items-center justify-center gap-4'>
            <div className='border border-white-100 rounded-full p-3'>
              <a href='/'>
                <img src={icon_1} alt='telegram' className='w-6 h-6'></img>
              </a>
            </div>

            <div className='border border-white-100 rounded-full p-3'>
              <a href='https://x.com/pnctoknofficial'>
                <img src={icon_2} alt='twitter' className='w-6 h-6'></img>
              </a>
            </div>
          </div>
        </div>

        <hr className='w-full h-0.3 bg-white-100'></hr>

        <p className='py-6 text-center'>Copyright {new Date().getFullYear()} | © Pinnacle Token All rights reserved.</p>
      </footer>
    </div>

  )
}
