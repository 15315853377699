import React, { useEffect } from 'react'
import Footer from './Footer'
import Menu from './Menu'
import f_1 from '../assets/f_1.png';
import f_2 from '../assets/f_2.png';
import f_3 from '../assets/f_3.png';
import f_4 from '../assets/f_4.png';
import f_5 from '../assets/f_5.png';
import f_6 from '../assets/f_6.png';
import f_7 from '../assets/f_7.png';
import f_8 from '../assets/f_8.png';
import f_9 from '../assets/f_9.png';
import f_10 from '../assets/f_10.png';
import pnc from '../assets/pnc.png';
import token from '../assets/token.png';
import g_1 from '../assets/g_1.png';
import g_2 from '../assets/g_2.png';
import g_3 from '../assets/g_3.png';
import g_4 from '../assets/g_4.png';
import g_5 from '../assets/g_5.png';
import g_6 from '../assets/g_6.png';
import g_7 from '../assets/g_7.png';
import line from '../assets/line.png';
import vector from '../assets/vector.png';
import partner_1 from '../assets/partner_1.png';
import partner_2 from '../assets/partner_2.png';
import explore from '../assets/explore.png';
import kyc from '../assets/kyc.png';

import Aos from 'aos';
import 'aos/dist/aos.css';
import Tilt from "react-parallax-tilt";

export default function Home() {

    useEffect(() => {
        Aos.init({
            duration: 600,
            easing: true
        });
        Aos.refresh();
    }, [])

    return (
        <div className="bg-black-50 font-montserat overflow-hidden text-white-100 bg_full">

            <section className=''>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </section>

            <div className="lg:px-20 px-5 relative z-20">
                <div className='container mx-auto'>

                    <img data-aos='slide-up' src={vector} alt="Logo" className='absolute right-0 mr-44 mt-44 w-16 animate-pulse lg:block hidden' />

                    <Menu />
                    <div className="flex flex-col justify-center items-center gap-y-12 lg:py-28 py-12">

                        <div className="text-center lg:w-10/12 font-cehua">
                            <p className='py-5 md:text-xl text-xs uppercase tracking-wider md:mb-3'>
                                a versatile, secure, and dynamic digital asset.
                            </p>

                            <img data-aos='slide-up' src={vector} alt="Logo" className='absolute ml-16 mt-36 w-16 animate-pulse lg:block hidden' />

                            <h2 data-aos='fade-up' className='md:text-5xl text-2xl md:tracking-wider'>
                                A leading digital asset in the DeFi ecosystem, providing unparalleled value and utility to our users and community.
                            </h2>

                            <p data-aos='fade-out' className='pt-8 pb-6 font-montserat md:leading-loose lg:w-9/12 flex mx-auto'>
                                PinnacleToken (PNC) is an ERC-20 compliant cryptocurrency designed to revolutionize decentralized finance (DeFi) by offering a versatile, secure, and dynamic digital asset. PinnacleToken integrates seamlessly with major DeFi platforms, leveraging advanced features to ensure robust security, transparent governance, and sustainable growth
                            </p>

                            <div>
                                <a href="/">
                                    <button className="text-white-100 btn-custom py-4 flex mx-auto btn-gradient rounded-full items-center gap-x-2 md:text-lg text-xs px-8 font-semibold mt-5 duration-75 hover:scale-110">
                                        <img src={explore} alt=''></img>
                                        Explore Now
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* about us section */}

            <div id='about' className="lg:px-20 px-5 bg-full">
                <div className='container mx-auto'>

                    <p className='tracking-wide font-semibold text-center pb-3'>About us</p>
                    <h3 className="md:text-5xl text-3xl font-cehua font-semibold text-center tracking-widest">
                        PinnacleToken (PNC)
                    </h3>

                    <div className="lg:flex block justify-between items-center gap-x-12 lg:py-12 py-6 lg:space-y-0 space-y-6">

                        <div data-aos="fade-right" className="lg:w-4/12 w-full">
                            <p className='md:leading-loose lg:text-right'>
                                PinnacleToken (PNC) is an ERC-20 compliant cryptocurrency designed to revolutionize decentralized finance (DeFi) by offering a versatile, secure, and dynamic digital asset. PinnacleToken integrates seamlessly with major DeFi platforms, leveraging advanced features to ensure robust security, transparent governance, and sustainable growth.
                            </p>
                        </div>

                        <div data-aos="zoom-in" data-aos-delay="350" className="lg:w-5/12 w-full">
                            <img src={pnc} alt="Logo" className='' />
                        </div>

                        <div data-aos="fade-left" className="lg:w-4/12 w-full">
                            <p className='md:leading-loose'>
                                Cryptocurrencies have evolved from being a niche interest to a global phenomenon, with DeFi leading the charge in creating decentralized financial systems. PinnacleToken aims to be at the forefront of this evolution by providing a token that combines the best of ERC-20 standards with innovative features designed to enhance usability, security, and community governance.
                            </p>
                        </div>

                    </div>

                    <div className="lg:flex block justify-between items-start gap-x-12 lg:py-12 py-6 lg:space-y-0 space-y-6">
                        <div data-aos="fade-right" className='lg:w-1/3'>
                            <p className="md:text-4xl text-2xl font-cehua font-semibold tracking-widest">
                                Our Vision
                            </p>
                            <p className='md:leading-loose py-5'>
                                To be a leading digital asset in the DeFi ecosystem, providing unparalleled value and utility to our users and community.
                            </p>
                            <a href='/' className='underline font-cehua text-xl'>
                                Learn More
                            </a>
                        </div>

                        <div data-aos="fade-left" className='lg:w-1/3 lg:text-right'>
                            <p className="md:text-4xl text-2xl font-cehua font-semibold tracking-widest">
                                Our Mission
                            </p>
                            <p className='md:leading-loose py-5'>
                                To empower users with a secure, transparent, and versatile cryptocurrency that supports a wide range of financial activities, from trading and staking to governance and real-world applications.
                            </p>
                            <a href='/' className='underline font-cehua text-xl'>
                                Learn More
                            </a>
                        </div>
                    </div>

                    {/* tokenomics */}

                    <div
                        id="tokenomics"
                        className="lg:py-12 py-5"
                    >
                        <h3 className="md:text-6xl text-4xl font-cehua font-semibold text-center tracking-widest">
                            Tokenomics
                        </h3>

                        <div className="lg:flex block justify-between items-center lg:py-16 py-6 lg:space-y-0 space-y-6">
                            <div className='lg:w-1/2 space-y-3'>

                                <div className='lg:text-2xl text-lg flex items-center gap-2'>
                                    <p className='font-cehua font-semibold tracking-widest'>Token Name:</p>
                                    PinnacleToken
                                </div>
                                <div className='lg:text-2xl text-lg flex items-center gap-2'>
                                    <p className='font-cehua font-semibold tracking-widest'>Symbol:</p> PNC
                                </div>
                                <div className='lg:text-2xl text-lg flex items-center gap-2'>
                                    <p className='font-cehua font-semibold tracking-widest'> Total Supply:</p> 100,000,000 PNC
                                </div>
                                <div className='lg:text-2xl text-lg flex items-center gap-2'>
                                    <p className='font-cehua font-semibold tracking-widest'> Decimals:</p> 18
                                </div>
                                <div className='lg:text-2xl text-lg flex items-center gap-2'>
                                    <p className='font-cehua font-semibold tracking-widest'> Initial Supply:</p> 100,000,000 PNC
                                </div>
                                <div className='lg:text-2xl text-lg flex items-center gap-2'>
                                    <p className='font-cehua font-semibold tracking-widest'> CEX Listing:</p> 30%
                                </div>
                                <div className='lg:text-2xl text-lg flex items-center gap-2'>
                                    <p className='font-cehua font-semibold tracking-widest'> Presale:</p> 25%
                                </div>
                                <div className='lg:text-2xl text-lg flex items-center gap-2'>
                                    <p className='font-cehua font-semibold tracking-widest'> Marketing:</p> 15%
                                </div>
                                <div className='lg:text-2xl text-lg flex items-center gap-2'>
                                    <p className='font-cehua font-semibold tracking-widest'> Development:</p> 10%
                                </div>
                                <div className='lg:text-2xl text-lg flex items-center gap-2'>
                                    <p className='font-cehua font-semibold tracking-widest'> Liquidity:</p> 10.2125%
                                </div>
                                <div className='lg:text-2xl text-lg flex items-center gap-2'>
                                    <p className='font-cehua font-semibold tracking-widest'> Unlocked:</p> 9.7875%
                                </div>

                            </div>

                            <div data-aos='zoom-in' className='lg:w-1/2'>
                                <img src={token} alt='' className='lg:w-10/12'></img>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            {/* features section */}

            <img src={line} alt='' className='absolute left-0 right-0 mt-32 w-full lg:block hidden'></img>

            <div id='features' className="lg:px-20 px-5 lg:py-16 py-6 bg-feature">
                <div className='container mx-auto'>

                    <div data-aos="fade-up" className="text-center font-cehua">
                        <h2 className='md:text-5xl text-3xl font-semibold text-white-100'>
                            Our Key Features
                        </h2>
                    </div>

                    <div data-aos='fade-right' className="lg:flex block justify-between items-start gap-x-12 lg:pt-16 lg:mr-44">

                        <div className="lg:w-1/2 w-full md:mt-0 mt-12">
                            <div className='lg:flex items-center gap-x-8 bg-black-25 md:py-6 md:pr-6 p-5 rounded-xl lg:space-y-0 space-y-4 border-custom'>
                                <img src={f_1} alt='' className='w-32'></img>

                                <div>
                                    <h2 className='md:text-xl text-xl mb-4 font-semibold text-white-100 capitalize'>
                                        ERC-20 Compliance
                                    </h2>
                                    <p className='text-white-50'>
                                        PinnacleToken adheres to the ERC-20 standard, ensuring compatibility with a wide range of wallets, exchanges, and DeFi platforms.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="lg:w-1/2 w-full md:mt-0 mt-12">
                            <div className='lg:flex items-center gap-x-8 bg-black-25 md:py-6 md:pr-6 p-5 rounded-xl lg:space-y-0 space-y-4 border-custom'>
                                <img src={f_2} alt='' className='w-32'></img>

                                <div>
                                    <h2 className='md:text-xl text-xl mb-4 font-semibold text-white-100 capitalize'>
                                        Ownership and Control:
                                    </h2>
                                    <p className='text-white-50'>
                                        Features ownership controls, including transfer of ownership and renouncement options, providing flexibility and security to the contract owner.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div data-aos='fade-left' data-aos-delay='150' className="lg:flex block justify-between items-start gap-x-12 lg:pt-16 lg:ml-48">

                        <div className="lg:w-1/2 w-full md:mt-0 mt-12">
                            <div className='lg:flex items-center gap-x-8 bg-black-25 md:py-6 md:pr-6 p-5 rounded-xl lg:space-y-0 space-y-4 border-custom'>
                                <img src={f_3} alt='' className='w-32'></img>

                                <div>
                                    <h2 className='md:text-xl text-xl mb-4 font-semibold text-white-100 capitalize'>
                                        Uniswap Integration:
                                    </h2>
                                    <p className='text-white-50'>
                                        Seamless integration with Uniswap V2, facilitating easy liquidity provision and token swapping.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="lg:w-1/2 w-full md:mt-0 mt-12">
                            <div className='lg:flex items-center gap-x-8 bg-black-25 md:py-6 md:pr-6 p-5 rounded-xl lg:space-y-0 space-y-4 border-custom'>
                                <img src={f_4} alt='' className='w-32'></img>

                                <div>
                                    <h2 className='md:text-xl text-xl mb-4 font-semibold text-white-100 capitalize'>
                                        Dynamic Fee Structure
                                    </h2>
                                    <p className='text-white-50'>
                                        Imposes customizable fees on buy and sell transactions, supporting marketing, development, and liquidity growth.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div data-aos='fade-right' data-aos-delay='300' className="lg:flex block justify-between items-start gap-x-12 lg:pt-16 lg:mr-48">

                        <div className="lg:w-1/2 w-full md:mt-0 mt-12">
                            <div className='lg:flex items-center gap-x-8 bg-black-25 md:py-6 md:pr-6 p-5 rounded-xl lg:space-y-0 space-y-4 border-custom'>
                                <img src={f_5} alt='' className='w-32'></img>

                                <div>
                                    <h2 className='md:text-xl text-xl mb-4 font-semibold text-white-100 capitalize'>
                                        SafeMath Library
                                    </h2>
                                    <p className='text-white-50'>
                                        Utilizes the SafeMath library to prevent overflows, ensuring secure mathematical operations.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="lg:w-1/2 w-full md:mt-0 mt-12">
                            <div className='lg:flex items-center gap-x-8 bg-black-25 md:py-6 md:pr-6 p-5 rounded-xl lg:space-y-0 space-y-4 border-custom'>
                                <img src={f_6} alt='' className='w-32'></img>

                                <div>
                                    <h2 className='md:text-xl text-xl mb-4 font-semibold text-white-100 capitalize'>
                                        Trading Control
                                    </h2>
                                    <p className='text-white-50'>
                                        Offers trading enablement/disabling capabilities with enforced maximum transaction and wallet limits to ensure fair and balanced trading.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div data-aos='fade-left' data-aos-delay='150' className="lg:flex block justify-between items-start gap-x-12 lg:pt-16 lg:ml-48">

                        <div className="lg:w-1/2 w-full md:mt-0 mt-12">
                            <div className='lg:flex items-center gap-x-8 bg-black-25 md:py-6 md:pr-6 p-5 rounded-xl lg:space-y-0 space-y-4 border-custom'>
                                <img src={f_7} alt='' className='w-32'></img>

                                <div>
                                    <h2 className='md:text-xl text-xl mb-4 font-semibold text-white-100 capitalize'>
                                        Automated Market Making
                                    </h2>
                                    <p className='text-white-50'>
                                        Automated liquidity management features that handle token swaps and liquidity addition, maintaining market stability.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="lg:w-1/2 w-full md:mt-0 mt-12">
                            <div className='lg:flex items-center gap-x-8 bg-black-25 md:py-6 md:pr-6 p-5 rounded-xl lg:space-y-0 space-y-4 border-custom'>
                                <img src={f_8} alt='' className='w-32'></img>

                                <div>
                                    <h2 className='md:text-xl text-xl mb-4 font-semibold text-white-100 capitalize'>
                                        Fee Exclusions
                                    </h2>
                                    <p className='text-white-50'>
                                        Allows specific addresses to be excluded from fees and transaction limits, offering flexibility for various use cases.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div data-aos='fade-right' data-aos-delay='300' className="lg:flex block justify-between items-start gap-x-12 lg:pt-16 lg:mr-48">

                        <div className="lg:w-1/2 w-full md:mt-0 mt-12">
                            <div className='lg:flex items-center gap-x-8 bg-black-25 md:py-6 md:pr-6 p-5 rounded-xl lg:space-y-0 space-y-4 border-custom'>
                                <img src={f_9} alt='' className='w-32'></img>

                                <div>
                                    <h2 className='md:text-xl text-xl mb-4 font-semibold text-white-100 capitalize'>
                                        Emergency Functions
                                    </h2>
                                    <p className='text-white-50'>
                                        Provides emergency features for rescuing ETH and ERC-20 tokens from the contract, ensuring user safety in unforeseen circumstances.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="lg:w-1/2 w-full md:mt-0 mt-12">
                            <div className='lg:flex items-center gap-x-8 bg-black-25 md:py-6 md:pr-6 p-5 rounded-xl lg:space-y-0 space-y-4 border-custom'>
                                <img src={f_10} alt='' className='w-32'></img>

                                <div>
                                    <h2 className='md:text-xl text-xl mb-4 font-semibold text-white-100 capitalize'>
                                        Event Logging
                                    </h2>
                                    <p className='text-white-50'>
                                        Logs significant transactions for transparency and traceability, including Transfer and Approval events.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            {/* roadmap */}

            <div
                id="roadmap"
                className="lg:py-12 py-6 lg:px-8 px-5 container mx-auto"
            >
                <h3 className="md:text-6xl text-4xl font-cehua font-semibold text-center tracking-widest">
                    Roadmap
                </h3>

                <div className="lg:mt-12 mt-6 lg:p-12 p-6">

                    <div className="h-1 w-full bg-white-50">

                    </div>

                    <div className='h-1 w-full line -translate-y-1'>

                    </div>

                    <div className="lg:flex items-baseline justify-center gap-6 lg:px-20 lg:space-y-0 space-y-6 -mt-2.5">

                        <div className="flex flex-col items-center">
                            <div
                                data-aos="zoom-in"
                                className="absolute -mt-3.5 lg:block hidden"
                            >
                                <svg width="38" height="39" viewBox="0 0 38 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle opacity="0.2" cx="19.0471" cy="19.4725" r="18.5609" fill="#FFC061CC" />
                                    <circle cx="19.0471" cy="19.4726" r="11.1365" fill="#FFC061CC" />
                                </svg>

                            </div>

                            <div className="border-l lg:mt-5 mt-0 mb-3 h-14"></div>

                            <div
                                data-aos="flip-right"
                                className="border rounded-2xl p-3 lg:w-64 "
                            >
                                <h5 className="text-lg pt-4 font-semibold">Q2 2024: Launch and Initial Growth</h5>

                                <ul className="list-disc p-3 text-sm text-gray-50 ">
                                    <li>Token Deployment and Initial Distribution</li>
                                    <li>Uniswap Liquidity Pool Creation</li>
                                    <li>Website Launch and Community Building</li>
                                    <li>Initial Exchange Listings</li>
                                </ul>
                            </div>
                        </div>

                        <div className="flex flex-col items-center">
                            <div
                                data-aos="zoom-in"
                                className="absolute -mt-3.5 lg:block hidden"
                            >
                                <svg width="38" height="39" viewBox="0 0 38 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle opacity="0.2" cx="19.0471" cy="19.4725" r="18.5609" fill="#FFC061CC" />
                                    <circle cx="19.0471" cy="19.4726" r="11.1365" fill="#FFC061CC" />
                                </svg>

                            </div>

                            <div className="border-l lg:mt-5 mt-0 mb-3 h-14"></div>

                            <div
                                data-aos="flip-right"
                                className="border rounded-2xl p-3 lg:w-64 "
                            >
                                <h5 className="text-lg pt-4 font-semibold">Q3 2024: Expansion and Utility</h5>

                                <ul className="list-disc p-3 text-sm text-gray-50 ">
                                    <li>Form Strategic Partnerships</li>
                                    <li>Launch Staking and Rewards Program</li>
                                    <li>Additional Exchange Listings</li>
                                    <li>Conduct Security Audits</li>
                                </ul>
                            </div>
                        </div>

                        <div className="flex flex-col items-center">
                            <div
                                data-aos="zoom-in"
                                className="absolute -mt-3.5 lg:block hidden"
                            >
                                <svg width="38" height="39" viewBox="0 0 38 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle opacity="0.2" cx="19.0471" cy="19.4725" r="18.5609" fill="#FFC061CC" />
                                    <circle cx="19.0471" cy="19.4726" r="11.1365" fill="#FFC061CC" />
                                </svg>

                            </div>

                            <div className="border-l lg:mt-5 mt-0 mb-3 h-14"></div>

                            <div
                                data-aos="flip-right"
                                className="border rounded-2xl p-3 lg:w-64 "
                            >
                                <h5 className="text-lg pt-4 font-semibold">Q4 2024: Ecosystem Development</h5>

                                <ul className="list-disc p-3 text-sm text-gray-50 ">
                                    <li>Integrate with DeFi Platforms</li>
                                    <li>Implement Decentralized Governance</li>
                                    <li>Develop Mobile Wallet Applications</li>
                                </ul>
                            </div>
                        </div>

                        <div className="flex flex-col items-center">
                            <div
                                data-aos="zoom-in"
                                className="absolute -mt-3.5 lg:block hidden"
                            >
                                <svg width="38" height="39" viewBox="0 0 38 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle opacity="0.2" cx="19.0471" cy="19.4725" r="18.5609" fill="#FFC061CC" />
                                    <circle cx="19.0471" cy="19.4726" r="11.1365" fill="#FFC061CC" />
                                </svg>
                            </div>

                            <div className="border-l lg:mt-5 mt-0 mb-3 h-14"></div>

                            <div
                                data-aos="flip-right"
                                className="border rounded-2xl p-3 lg:w-64 "
                            >
                                <h5 className="text-lg pt-4 font-semibold">Q1 2025: Mass Adoption and Innovation</h5>

                                <ul className="list-disc p-3 text-sm text-gray-50 ">
                                    <li>Achieve Cross-Chain Compatibility</li>
                                    <li>Drive Merchant Adoption</li>
                                    <li>Launch Educational Initiatives</li>
                                </ul>
                            </div>
                        </div>

                        <div className="flex flex-col items-center">
                            <div
                                data-aos="zoom-in"
                                className="absolute -mt-3.5 lg:block hidden"
                            >
                                <svg width="38" height="39" viewBox="0 0 38 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle opacity="0.2" cx="19.0471" cy="19.4725" r="18.5609" fill="#FFC061CC" />
                                    <circle cx="19.0471" cy="19.4726" r="11.1365" fill="#FFC061CC" />
                                </svg>
                            </div>

                            <div className="border-l lg:mt-5 mt-0 mb-3 h-14"></div>

                            <div
                                data-aos="flip-right"
                                className="border rounded-2xl p-3 lg:w-64 "
                            >
                                <h5 className="text-lg pt-4 font-semibold">Q2 2025 and Beyond: Continuous Growth</h5>

                                <ul className="list-disc p-3 text-sm text-gray-50 ">
                                    <li>Expand to International Markets</li>
                                    <li>Innovate and Update Ecosystem</li>
                                    <li>Ensure Sustainable Development</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {/* governanace */}

            <div
                id="governance"
                className="lg:py-12 py-5 lg:px-20 px-5 container mx-auto"
            >
                <div>
                    <h3 className="md:text-6xl text-4xl font-cehua font-semibold text-center tracking-widest">
                        Governance
                    </h3>
                    <p className='flex mx-auto md:w-8/12 text-center md:leading-loose mt-8'>
                        PinnacleToken will implement a decentralized governance model, allowing token holders to propose and vote on significant decisions. This model ensures that the community has a direct impact on the project's direction and development.
                    </p>
                </div>

                <div className="lg:mt-12 mt-6 lg:flex justify-between items-center gap-6 lg:space-y-0 space-y-6">

                    <div>
                        <div className='grid lg:grid-cols-3 grid-cols-1 justify-between items-center gap-6'>

                            <Tilt glareEnable={false} tiltMaxAngleX={10}
                                tiltMaxAngleY={10} perspective={1000}>
                                <div className='space-y-4 border-2 border-yellow-100 rounded-lg p-5'>
                                    <img src={g_1} alt=''></img>
                                    <p className='text-xl font-semibold'>Seemless Integration with Uniswap</p>
                                    <p className='text-white-50'>
                                        Effortlessly add and manage liquidity with PinnacleToken's built-in Uniswap V2 integration. Enjoy smooth and secure trading, ensuring you always get the best market prices.
                                    </p>
                                </div>
                            </Tilt>

                            <Tilt glareEnable={false} tiltMaxAngleX={10}
                                tiltMaxAngleY={10} perspective={1000}>
                                <div className='space-y-4 border-2 border-yellow-100 rounded-lg p-5 lg:translate-y-24'>
                                    <img src={g_2} alt=''></img>
                                    <p className='text-xl font-semibold'>Dynamic Fee Structure</p>
                                    <p className='text-white-50'>
                                        Our intelligent fee system allocates transaction fees towards marketing, development, and liquidity, ensuring continuous growth and stability. Customize fees for buy and sell transactions, keeping control in your hands.
                                    </p>
                                </div>
                            </Tilt>

                            <Tilt glareEnable={false} tiltMaxAngleX={10}
                                tiltMaxAngleY={10} perspective={1000}>
                                <div className='space-y-4 border-2 border-yellow-100 rounded-lg p-5'>
                                    <img src={g_3} alt=''></img>
                                    <p className='text-xl font-semibold'>Safe and Secure Transactions</p>
                                    <p className='text-white-50'>
                                        Utilizing the SafeMath library, PinnacleToken ensures all mathematical operations are secure, preventing overflows and guaranteeing the safety of your assets.
                                    </p>
                                </div>
                            </Tilt>

                        </div>

                        <div className='grid lg:grid-cols-3 grid-cols-1 justify-between items-center gap-6 mt-6'>

                            <Tilt glareEnable={false} tiltMaxAngleX={10}
                                tiltMaxAngleY={10} perspective={1000}>
                                <div className='space-y-4 border-2 border-yellow-100 rounded-lg p-5'>
                                    <img src={g_4} alt=''></img>
                                    <p className='text-xl font-semibold'>Ownership and Control</p>
                                    <p className='text-white-50'>
                                        PinnacleToken empowers you with robust ownership controls. Transfer or renounce ownership with ease, and manage important aspects of your token with full transparency.
                                    </p>
                                </div>
                            </Tilt>

                            <Tilt glareEnable={false} tiltMaxAngleX={10}
                                tiltMaxAngleY={10} perspective={1000}>
                                <div className='space-y-4 border-2 border-yellow-100 rounded-lg p-5 lg:translate-y-24'>
                                    <img src={g_5} alt=''></img>
                                    <p className='text-xl font-semibold'>Trade with Confidence</p>
                                    <p className='text-white-50'>
                                        Enable trading with a simple command. Once activated, trading remains seamless and uninterrupted. Our trading limits ensure a balanced and fair market for all participants.
                                    </p>
                                </div>
                            </Tilt>

                            <Tilt glareEnable={false} tiltMaxAngleX={10}
                                tiltMaxAngleY={10} perspective={1000}>
                                <div className='space-y-4 border-2 border-yellow-100 rounded-lg p-5'>
                                    <img src={g_6} alt=''></img>
                                    <p className='text-xl font-semibold'>Mobile +Automated Market Making Web3 Compatible</p>
                                    <p className='text-white-50'>
                                        Benefit from our automated liquidity management, which seamlessly adds liquidity and swaps tokens for ETH when thresholds are met. This ensures constant market stability and liquidity.
                                    </p>
                                </div>
                            </Tilt>

                        </div>
                    </div>

                    <Tilt glareEnable={false} tiltMaxAngleX={10}
                        tiltMaxAngleY={10} perspective={1000}>
                        <div className='space-y-4 border-2 border-yellow-100 rounded-lg p-5'>
                            <img src={g_7} alt=''></img>
                            <p className='text-xl font-semibold'>Transparent and Traceable</p>
                            <p className='text-white-50'>
                                All significant transactions are logged with Transfer and Approval events, offering complete transparency and traceability for every token movement.
                            </p>
                        </div>
                    </Tilt>

                </div>
            </div>

            <div className="lg:pt-32 py-6 lg:px-20 px-5 lg:mb-12 container mx-auto">

                <p className="md:text-5xl text-3xl font-cehua font-semibold text-center tracking-wide">
                    KYC Certified
                </p>

                <img data-aos='fade-up' src={kyc} alt='partner' className='flex mx-auto lg:w-10/12 md:mt-20 mt-8'></img>
            </div>

            <div className="lg:pt-20 pb-20 py-6 lg:px-20 px-5 container mx-auto">

                <p className="md:text-5xl text-3xl font-cehua font-semibold text-center tracking-wide">
                    Our Partners
                </p>

                <div className='flex items-end justify-center md:gap-24 gap-12 md:mt-16 mt-8'>
                    <a href='https://www.pinksale.finance/launchpad/base/0x3d7b05d034c3DDca5Bb15251bC18B7F65fd1c5f0'><img src={partner_1} alt='partner' className='w-36'></img></a>
                    <a href='https://www.dexview.com/base/0x73aE332E0C312f94Ba9052b527fbCC226cf8519E'><img src={partner_2} alt='partner' className='w-48'></img></a>
                </div>
            </div>

            <Footer />

        </div >
    )
}
