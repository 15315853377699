import React, { useState } from 'react'
import logo from '../assets/logo.png';



export default function Menu() {

  const [show, setShow] = useState(false);


  return (

    <header>

      <div className="lg:flex hidden items-center justify-between font-montserat h-28">
        <a href="/" className="logo lg:my-12">
          <img src={logo} alt="Logo" />
        </a>

        <nav>
          <ul className="text-md flex justify-center gap-8 pt-4 items-center text-white-100">
            <li><a href="/" className="border-b-2 border-yellow-100 pb-2">Home</a></li>
            <li><a href="#tokenomics" className="hover:border-b-2 border-yellow-100 pb-2">Tokenomics</a></li>
            <li><a href="#roadmap" className="hover:border-b-2 border-yellow-100 pb-2">Roadmap</a></li>
            <li><a href="#governance" className='hover:border-b-2 border-yellow-100 pb-2'>Governance</a></li>
          </ul>
        </nav>

        <a href="/">
          <button className="btn-gradient py-3 lg:px-6 px-4 font-semibold text-black-100 rounded-lg">
            Connect wallet
          </button>
        </a>
      </div>

      <div className="lg:hidden flex items-center justify-between pt-5 font-montserat">
        <a href="/" className="logo lg:my-12">
          <img src={logo} alt="Logo" className='w-9/12' />
        </a>

        <button onClick={() => setShow(!show)} className="nav cursor-pointer">

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8 text-white-100">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"></path>
          </svg>

        </button>
      </div>

      {
        show ? <div className="fixed top-0 bottom-0 left-0 p-2 w-3/4 overflow-y-auto font-semibold btn-gradient z-20">

          <div className="mt-3 px-3 mb-12">
            <a href="/" className="pr-2">
              <img src={logo} alt="Logo" className='w-9/12' />
            </a>
          </div>

          <div>
            <a href="/" className="text-lg ml-4 font-semibold">
              <button onClick={() => setShow(!show)} className="py-2.5 mt-3 text-white-100">
                Home
              </button>
            </a>
          </div>

          <div>
            <a href="#tokenomics" className="text-lg ml-4 font-semibold">
              <button onClick={() => setShow(!show)} className="py-2.5 mt-3 text-white-100">
                Tokenomics
              </button>
            </a>
          </div>

          <div>
            <a href="#roadmap" className="text-lg ml-4 font-semibold">
              <button onClick={() => setShow(!show)} className="py-2.5 mt-3 text-white-100">
                Roadmap
              </button>
            </a>
          </div>

          <div>
            <a href="#governance" className="text-lg ml-4 font-semibold">
              <button onClick={() => setShow(!show)} className="py-2.5 mt-3 text-white-100">
                Governance
              </button>
            </a>
          </div>

          <a href="/" className="text-lg ml-4 font-semibold">
            <button onClick={() => setShow(!show)} className="w-full px-5 py-3 mt-3 flex items-center rounded-md duration-300 cursor-pointer  text-white-100 bg-black-50">
              Connect Wallet
            </button>
          </a>

        </div> : null
      }


    </header >
  )
}
